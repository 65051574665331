@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  html {
    @apply flex min-h-full;
  }

  body {
    @apply flex-auto flex;
  }

  #root {
    @apply flex-auto flex flex-col;
  }

  main {
    .text-align-center {
      @apply text-center;
    }

    b, strong {
      @apply font-semibold;
    }


    .text-4xl,
    .text-5xl,
    .headline {
      b, strong {
        @apply font-black;
      }
    }
    // p {
    //   @apply my-1;
    // }

    
  }
}

@layer components {
  .group {
    .container {
      @apply px-0;
    }
  }

  .box {
    @apply flex-auto flex;
  }

  .col {
    @apply flex-auto flex flex-col;
  }

  .headline {
    h1 {@apply text-5xl/9;}
    h2 {@apply text-5xl/7;}
    h3 {@apply text-3xl/5;}

  }


  .subline {
    h1 {@apply text-4xl/6 leading-tight;}
    h2 {@apply text-3xl/5 leading-tight;}
    h3 {@apply text-2xl/4 leading-tight;}
    h4 {@apply text-2xl/3 leading-tight;}
  }

  div.parsed {
 



    // h2 {
    //   @apply text-4xl font-bold leading-tight tracking-wide mb-5;
    // }

    // h3 {
    //   @apply text-2xl leading-tight tracking-wide mb-3;
    // }

    p{
      @apply my-3;
    }

    ul, ol {
      @apply pl-6 py-1;
  
      li {
        @apply list-disc pt-1;
      }
    }


  }


  // h1, h2, h3, h4, h5, h6 {
  //   p {
  //     @apply my-0 ;
  //   }
  // }

  // .group {
  //   .parsed {
  //     h2 {@apply text-2xl mb-3;}
  //     h3 {@apply text-xl;}
  //   }
  // }
}
  
main {
  .text-align-center {
    @apply text-center;
  }
  .text-align-right {
    @apply text-right;
  }
 
}



@keyframes spinner {
  0% { transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

.loader {
  width: inherit;
  height: inherit;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  animation: spinner 700ms infinite linear;

  &.light {
    border-color: rgba(255, 255, 255, 0.08);
    border-left-color: rgba(255, 255, 255, 0.5);
  }
}